import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Over Ons</h2>
          <span className="image main">
            <img src={pic01} alt="" style={{ maxHeight: 280, objectFit: 'cover', objectPosition: 'center' }} />
          </span>
          <p>
            Heeft u een idee voor een mobiele applicatie, of alleen een
            doelstelling? In beide gevallen bent u bij Repeat Software aan het
            juiste adres. Repeat fungeert graag als een sparringpartner. Het
            volledige team denkt met u mee. Met onze ervaring in het ontwikkelen
            van webapplicaties hebben we veel langs zien komen, de ideale
            voedingsbodem voor nieuwe inzichten en ideeën.
          </p>
          <p>
            Hoe kunnen potentiële klanten een winkel via GPS vinden of
            medewerkers hun uren bijhouden als de nieuwe applicatie geen
            verbinding maakt met een achterliggend CMS? Repeat Software bouwt
            maatwerk software met diepgaande integraties met bestaande software.
            Soms is er geen nieuwe software nodig om een bedrijf efficiënter te
            maken en volstaan integraties. Voor zowel nieuwe functionaliteiten
            als deze koppelingen met bestaande bedrijfssoftware, bent u bij Repeat
            aan het juiste adres.
          </p>
          <p>
            Alle belangrijke websites en applicaties van onze partners kunnen in de cloud gehost worden. Voor
            de hosting van uw software kunt u dus ook bij ons terecht. Dit maakt
            Repeat een one-stop-shop voor het complete pakket; ontwikkeling,
            onderhoud en hosting van uw software op maat. Gewoon uit de cloud!
          </p>
          {close}
        </article>

        {/* <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Klanten</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <p>
            Heeft u een idee voor een mobiele applicatie, of alleen een
            doelstelling? In beide gevallen bent u bij Repeat Software aan het
            juiste adres. Repeat fungeert graag als een sparringpartner. Het
            volledige team denkt met u mee. Met onze ervaring in het ontwikkelen
            van webapplicaties hebben we veel langs zien komen, de ideale
            voedingsbodem voor nieuwe inzichten en ideeën.
          </p>
          {close}
        </article> */}

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Werkwijze</h2>
          <span className="image main">
            <img src={pic03} alt="" style={{ maxHeight: 280, objectFit: 'cover', objectPosition: 'center' }} />
          </span>
          <p>
            We ontwikkelen software op een effectieve en flexibele manier. We
            hanteren hierbij de Scrum methode, waarbij iedere paar weken een
            nieuw stukje software wordt opgeleverd die zowel door het team als
            de opdrachtgever wordt geëvalueerd. Hierdoor blijft de opdrachtgever
            in controle over het eindresultaat en is er ruimte voor
            voortschrijdende inzichten.
          </p>
          <p>
            Software is nooit klaar. Voortschrijdend inzicht, aanmerkingen van
            gebruikers en nieuwe ontwikkelen vragen om (kleine) verbeteringen en
            aanpassingen van de software. Door dit onderhoud te bieden,
            garanderen we een langere levensduur van uw software.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact informatie</h2>
          <div>
            <div>Repeat Software</div>
            <div>Prof. W.H. Keesomlaan 12</div>
            <div>1183DJ Amstelveen</div>
            <div>Nederland</div>
            <br />
            <div>+31 (0) 613 314 356</div>
            <div>info@repeat.rocks</div>
            <br />
            <div>KVK: 84480467</div>
            <br />
          </div>
          <ul className="icons">
            {/* <li>
              <a
                href="https://twitter.com/HuntaroSan"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li> */}
            <li>
              <a
                href="https://gitlab.com/repeat.rocks"
                className="icon fa-gitlab"
              >
                <span className="label">GitLab</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/repeatnl" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a href="mailto:contact@repeat.rocks" className="icon fa-at">
                <span className="label">Email</span>
              </a>
            </li>
            <li>
              <a href="tel:+31(0)613314356" className="icon fa-phone">
                <span className="label">Telefoon</span>
              </a>
            </li>
          </ul>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Naam</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">E-mail</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Bericht</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input
                  type="submit"
                  value="Stuur bericht"
                  className="special"
                />
              </li>
            </ul>
          </form> */}
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
